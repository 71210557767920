import styled from "styled-components";

export const RedLi = styled.li`
  margin-bottom: 10px;
  color: #cc1616;
`;

export const PwaLi = styled.li`
  margin-bottom: 10px;
  color: black;
`;

export const PwaUl = styled.ul`
  list-style-type: none;
`;
